import React from 'react';
import { CONTAINER_BREAK_TYPES } from '../../constants/generic';
import { StyledContainerBreak, StyledContainerBreakProps } from './ContainerBreak.styled';

interface ContainerBreakProps extends StyledContainerBreakProps {
  children: React.ReactNode;
  className?: string;
}

const ContainerBreak = ({ children, className, type = CONTAINER_BREAK_TYPES.BOTH }: ContainerBreakProps) => (
  <StyledContainerBreak className={className} type={type}>
    {children}
  </StyledContainerBreak>
);

export default ContainerBreak;
