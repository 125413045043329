import { apiClient } from 'helpers/ApiClient';
import { logErrorResponse } from 'utils/loggerUtil';

export type FetchKiyOhReviewsParams = {
  locale: string;
};

const fetchKiyOhReviews = async ({ locale }: FetchKiyOhReviewsParams) => {
  try {
    const { data } = await apiClient({
      params: {
        lang: locale,
      },
      url: `/kiyoh`,
    });

    return data;
  } catch (error) {
    logErrorResponse('KiyOh Connector', error, `Error fetching kiyOh reviews`);
    throw error;
  }
};

export { fetchKiyOhReviews };
