import Link from 'components/Link/Link';
import styled, { css } from 'styled-components';
import { breakpointUp, spacer } from 'theme';
import { BREAKPOINTS } from '../../../constants/breakpoints';
import HelpSection from './HelpSection/HelpSection';

export const StyledFooterHelpSectionSections = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacer(200)};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      flex-direction: row;
      gap: ${spacer(150)};
    `,
  )}
`;

export const StyledHelpSection = styled(HelpSection)`
  max-width: 300px;
`;

export const StyledLink = styled(Link)`
  display: flex;
  margin-top: auto;
  align-items: center;
  gap: ${spacer(100)};
`;
