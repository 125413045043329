import styled, { css } from 'styled-components';
import { breakpointUp, spacer } from 'theme';
import { BREAKPOINTS } from '../../../constants/breakpoints';

interface MinimalProps {
  minimal?: boolean;
}

export const StyledFooterCompanyInfoBar = styled.div`
  margin-top: ${spacer(150)};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: flex;
      justify-content: space-between;
    `,
  )}
`;

export const StyledFooterCompanyInfoBarCompanyLinks = styled.div<MinimalProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${spacer(200)};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css<MinimalProps>`
      flex-direction: row;
      align-items: unset;
      gap: ${spacer(150)};
    `,
  )}
`;

export const StyledImageWrapper = styled.div`
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledFooterCompanyInfoBarPaymentMethods = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${spacer(75)};
  justify-items: center;

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      display: flex;
      margin-bottom: ${spacer(200)};
    `,
  )};
`;
