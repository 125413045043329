import { FOOTER_COMPANY_INFO } from 'constants/footer';
import { useIntl } from 'react-intl';
import { PaymentMethod } from 'types/Payment';
import { Text } from 'ui/Text/Text';
import Flex from '../../Flex/Flex';
import Image from '../../Image/Image';
import CMSPageLink from '../../Router/Link/CMSPageLink/CMSPageLink';
import FooterCompanyInfo from '../FooterCompanyInfo/FooterCompanyInfo';
import {
  StyledFooterCompanyInfoBar,
  StyledFooterCompanyInfoBarCompanyLinks,
  StyledFooterCompanyInfoBarPaymentMethods,
  StyledImageWrapper,
} from './FooterCompanyInfoBar.styled';

interface FooterCompanyInfoBarProps {
  className?: string;
  minimal?: boolean;
  paymentMethods: PaymentMethod[];
}

const FooterCompanyInfoBar = ({ className, minimal, paymentMethods }: FooterCompanyInfoBarProps) => {
  const { formatMessage } = useIntl();

  return (
    <StyledFooterCompanyInfoBar className={className}>
      <Flex flexDirection="column" gap={200}>
        {!!paymentMethods?.length && (
          <StyledFooterCompanyInfoBarPaymentMethods>
            {paymentMethods.map(({ image, name }) => {
              if (!image) {
                return null;
              }

              return (
                <StyledImageWrapper key={name}>
                  <Image
                    alt={name}
                    height={50}
                    sizes={{
                      default: 50,
                    }}
                    src={image}
                    width={60}
                  />
                </StyledImageWrapper>
              );
            })}
          </StyledFooterCompanyInfoBarPaymentMethods>
        )}

        <StyledFooterCompanyInfoBarCompanyLinks minimal={minimal}>
          {FOOTER_COMPANY_INFO.map((info) => (
            <CMSPageLink page={info.page} key={info.page}>
              <Text className="leading-8 text-footer-cms-link">{formatMessage({ id: info.text })}</Text>
            </CMSPageLink>
          ))}
        </StyledFooterCompanyInfoBarCompanyLinks>
      </Flex>

      <FooterCompanyInfo />
    </StyledFooterCompanyInfoBar>
  );
};

export default FooterCompanyInfoBar;
