import styled, { css } from 'styled-components';
import { breakpointUp, spacer } from 'theme';
import { BREAKPOINTS } from '../../../constants/breakpoints';

export const StyledFooterCommerceBadges = styled.div`
  display: flex;
  gap: ${spacer(50)};
  margin-top: ${spacer(175)};

  ${breakpointUp(
    BREAKPOINTS.LG,
    css`
      margin-top: 0px;
      justify-content: flex-end;
    `
  )}
`;
