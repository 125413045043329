import { CMSFooterComponent } from 'types/CMS';

type CMSSlot = {
  components: CMSFooterComponent[];
  linkComponents: unknown[];
  position: string;
  title: string;
};

export const getComponentsFromSlotPosition = (slots: CMSSlot[], position: string) => {
  return slots?.find((slot) => slot?.position === position)?.components ?? [];
};
