import BeCommerceBadge from './BeCommerceBadge/BeCommerceBadge';
import EuronicsBadge from './EuronicsBadge/EuronicsBadge';
import { StyledFooterCommerceBadges } from './FooterCommerceBadges.styled';
import KiyohBadge from './KiyOhBadge/KiyOhBadge';

type Props = {
  className?: string;
};

const FooterCommerceBadges = ({ className }: Props) => (
  <StyledFooterCommerceBadges className={className}>
    <BeCommerceBadge />
    <EuronicsBadge />
    <KiyohBadge />
  </StyledFooterCommerceBadges>
);

export default FooterCommerceBadges;
