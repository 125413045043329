import CMSComponent from 'components/CMS/CMSComponent/CMSComponent';
import { CMSFooterComponent } from 'types/CMS';
import { cn } from 'utils/cn';

interface CMSFooterLinkBarProps {
  className?: string;
  footerBottomSlot: CMSFooterComponent[];
}

const CMSFooterLinkBar = ({ className, footerBottomSlot }: CMSFooterLinkBarProps) => {
  if (!footerBottomSlot?.length) {
    return null;
  }

  return (
    <div className={cn('flex flex-col md:grid md:grid-cols-4', className)}>
      {footerBottomSlot.map((component, index) => (
        //FIXME: Typecasting as these component types don't align
        <CMSComponent key={index} cmsComponent={component as unknown as CMSComponent} index={index} />
      ))}
    </div>
  );
};

export default CMSFooterLinkBar;
