import styled, { css } from 'styled-components';
import { CONTAINER_BREAK_TYPES, ContainerBreak } from '../../constants/generic';

const breakStyling = css`
  content: '';
  position: absolute;
  width: calc((100vw - 100%) / 2);
  top: 0;
  height: 100%;
`;

export interface StyledContainerBreakProps {
  type?: ContainerBreak;
}

export const StyledContainerBreak = styled.div<StyledContainerBreakProps>`
  position: relative;

  ${({ type }) =>
    (type === CONTAINER_BREAK_TYPES.BEFORE || type === CONTAINER_BREAK_TYPES.BOTH) &&
    css`
      &::before {
        ${breakStyling}
      }

      &::before {
        right: 100%;
      }
    `}

  ${({ type }) =>
    (type === CONTAINER_BREAK_TYPES.AFTER || type === CONTAINER_BREAK_TYPES.BOTH) &&
    css`
      &::after {
        ${breakStyling}
      }

      &::after {
        left: 100%;
      }
    `}
`;
