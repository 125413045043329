import { useQuery } from '@tanstack/react-query';
import { useWebConfig } from 'features/configuration/queries';
import useRouter from 'hooks/useRouter';
import { kiyOhKeys } from '.';
import { fetchKiyOhReviews } from './connector';

const useKiyohReviews = () => {
  const { data: webConfig } = useWebConfig();
  const enableKiyoh = webConfig?.enableKiyoh;
  const { locale } = useRouter();

  return useQuery({
    enabled: enableKiyoh,
    queryFn: () => fetchKiyOhReviews({ locale }),
    queryKey: kiyOhKeys.kiyOhReviews({ locale }),
  });
};

export { useKiyohReviews };
