import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { breakpointUp, color, spacer } from 'theme';
import { BREAKPOINTS } from '../../../constants/breakpoints';

interface StyledFooterCompanyInfoBarInfoListProps extends HTMLAttributes<HTMLUListElement> {
  showAsRowOnDesktop?: boolean;
}

export const StyledFooterCompanyInfoBarInfoList = styled.ul<StyledFooterCompanyInfoBarInfoListProps>`
  text-align: center;
  padding-left: 0px;
  margin-bottom: ${spacer(100)};

  ${breakpointUp(
    BREAKPOINTS.MD,
    css`
      text-align: right;
      max-width: 320px;
    `,
  )}
  ${({ showAsRowOnDesktop }) =>
    showAsRowOnDesktop &&
    breakpointUp(
      BREAKPOINTS.MD,
      css`
        display: flex;
        justify-content: space-between;
        max-width: 100%;
      `,
    )}
`;

interface StyledFooterCompanyInfoBarInfoListCopyProps extends HTMLAttributes<HTMLLIElement> {
  copyright?: boolean;
}

export const StyledFooterCompanyInfoBarInfoListCopy = styled.li<StyledFooterCompanyInfoBarInfoListCopyProps>`
  list-style: none;
  color: ${color('whisper-300')};

  ${({ copyright }) =>
    copyright &&
    css`
      margin-top: ${spacer(150)};

      ${breakpointUp(
        BREAKPOINTS.MD,
        css`
          margin-top: 0px;
        `,
      )}
    `}
`;
