import { useIntl } from 'react-intl';
import {
  StyledFooterCompanyInfoBarInfoList,
  StyledFooterCompanyInfoBarInfoListCopy,
} from './FooterCompanyInfo.styled';

interface FooterCompanyInfoProps {
  showAsRowOnDesktop?: boolean;
}

const FooterCompanyInfo = ({ showAsRowOnDesktop }: FooterCompanyInfoProps) => {
  const { formatMessage } = useIntl();

  const year = new Date().getFullYear();

  return (
    <StyledFooterCompanyInfoBarInfoList showAsRowOnDesktop={showAsRowOnDesktop}>
      <StyledFooterCompanyInfoBarInfoListCopy>
        <span
          dangerouslySetInnerHTML={{
            __html: formatMessage({ id: 'footer_info_company_address' }),
          }}
        />
      </StyledFooterCompanyInfoBarInfoListCopy>
      <StyledFooterCompanyInfoBarInfoListCopy>
        <span
          dangerouslySetInnerHTML={{
            __html: formatMessage({ id: 'footer_info_btw' }),
          }}
        />
      </StyledFooterCompanyInfoBarInfoListCopy>
      <StyledFooterCompanyInfoBarInfoListCopy copyright>
        <span
          dangerouslySetInnerHTML={{
            __html: formatMessage({ id: 'footer_info_copyright' }, { year }),
          }}
        />
      </StyledFooterCompanyInfoBarInfoListCopy>
    </StyledFooterCompanyInfoBarInfoList>
  );
};

export default FooterCompanyInfo;
