import Flex from 'components/Flex/Flex';
import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import { FooterSocialLink } from 'types/Footer';
import { StyledFooterSocials } from './FooterSocials.styled';

interface FooterSocialsProps {
  socialLinks?: FooterSocialLink[];
}

const FooterSocials = ({ socialLinks }: FooterSocialsProps) => (
  <StyledFooterSocials>
    {socialLinks?.map(({ image, link }) => {
      if (!(link && image?.url)) {
        return null;
      }

      return (
        <li key={link}>
          <Link url={link} newTab>
            <Flex alignItems="center">
              <Image alt={image?.code} height={25} src={image?.url} width={25} />
            </Flex>
          </Link>
        </li>
      );
    })}
  </StyledFooterSocials>
);

export default FooterSocials;
