import Flex from 'components/Flex/Flex';
import React from 'react';
import Image from '../../../Image/Image';
import Link from '../../../Link/Link';
import { useWebConfig } from 'features/configuration/queries';

const BeCommerceBadge = () => {
  const { data: webConfig } = useWebConfig();

  const belgianBeCommerceLogo = webConfig?.belgianBeCommerceLogo;
  const belgianBeCommerceUrl = webConfig?.belgianBeCommerceUrl;
  const enableBeCommerce = webConfig?.enableBeCommerce;
  const europeanBeCommerceLogo = webConfig?.europeanBeCommerceLogo;
  const europeanBeCommerceUrl = webConfig?.europeanBeCommerceUrl;

  if (!enableBeCommerce) {
    return null;
  }

  return (
    <Flex gap={50}>
      {europeanBeCommerceLogo?.url && europeanBeCommerceUrl && (
        <Link newTab url={europeanBeCommerceUrl}>
          <Image alt="European BeCommerce" height={55} src={europeanBeCommerceLogo?.url} width={55} />
        </Link>
      )}

      {belgianBeCommerceLogo?.url && belgianBeCommerceUrl && (
        <Link newTab url={belgianBeCommerceUrl}>
          <Image alt="Belgian BeCommerce" height={55} src={belgianBeCommerceLogo?.url} width={55} />
        </Link>
      )}
    </Flex>
  );
};

export default BeCommerceBadge;
