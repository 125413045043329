import { HTMLAttributes } from 'react';

const KiyOhIcon = (props: HTMLAttributes<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 450 420" width="450" height="420" {...props}>
    <g id="#ffffffff" />
    <g id="#efd70dff">
      <path
        id="Layer"
        className="shp0"
        fill="#efd70d"
        d="M163.72,20.65c48.8,-16.99 104.3,-13.83 150.69,9.04c10.1,4.96 19.8,10.74 28.9,17.38c-22.64,11.32 -43.26,26.57 -61.05,44.55c-29.89,-15.12 -65.99,-17.34 -97.46,-5.83c-25.29,8.99 -47.38,26.53 -62.01,49.01c-9.59,14.48 -15.95,31.06 -18.67,48.2c-22.98,-4.32 -46.03,-8.3 -68.97,-12.83c6.77,-37.08 24.68,-72 50.7,-99.24c21.45,-22.65 48.42,-40.01 77.87,-50.28z"
      />
      <path
        id="Layer"
        className="shp0"
        fill="#efd70d"
        d="M274.52,87.91c3.42,-23 10.85,-45.29 21.47,-65.82c35.86,12.99 67.78,37.19 90.24,68.37c-19.13,13.87 -38.04,28.04 -57.18,41.92c-13.35,-19.82 -32.71,-35.3 -54.54,-44.47z"
      />
      <path
        id="Layer"
        className="shp0"
        fill="#efd70d"
        d="M316.11,116.5c12.88,-19.89 29.24,-37.47 47.86,-52.1c28.29,26.82 48.16,62.4 56.2,100.54c-23.83,4.95 -47.58,10.28 -71.42,15.24c-4.24,-23.86 -15.95,-46.18 -32.64,-63.68z"
      />
    </g>
    <g id="#67b03dff" />
    <g id="#cad129ff">
      <path
        id="Layer"
        className="shp1"
        fill="#cad129"
        d="M348.75,180.18c23.84,-4.96 47.59,-10.29 71.42,-15.24c9.11,42.21 3.82,87.37 -14.79,126.33c-16.15,34.23 -42.11,63.87 -74.27,83.88c-13.44,-22.09 -27.13,-44.04 -40.37,-66.25c11.63,-6.99 22.13,-15.9 30.83,-26.32c23.79,-27.79 34.05,-66.47 27.18,-102.4z"
      />
    </g>
    <g id="#e49219ff">
      <path
        id="Layer"
        className="shp2"
        fill="#e49219"
        d="M35.15,170.17c22.94,4.53 45.99,8.51 68.97,12.83c-5.1,30.42 2.09,62.54 19.16,88.17c-20.66,12.88 -41.44,25.58 -62.03,38.57c-25.8,-41.09 -35.02,-91.96 -26.1,-139.57z"
      />
    </g>
    <g id="#d1380eff">
      <path
        id="Layer"
        className="shp3"
        fill="#d1380e"
        d="M61.25,309.74c20.59,-12.99 41.37,-25.69 62.03,-38.57c12.12,18.35 29.24,33.37 49.02,42.98c-15.22,30.32 -30.97,60.37 -46.32,90.63c-29.58,-6.18 -58.4,-16.37 -84.99,-30.76c-5.14,-2.84 -10.24,-5.79 -15.04,-9.17c22.89,-3.84 45.68,-8.57 67.85,-15.52c-12.63,-11.59 -23.41,-25.12 -32.55,-39.59z"
      />
    </g>
    <g id="#94be1cff">
      <path
        id="Layer"
        className="shp4"
        fill="#94be1c"
        d="M227.96,326.76c22.01,-0.29 43.97,-6.37 62.78,-17.86c13.24,22.21 26.93,44.16 40.37,66.25c-13.99,8.87 -29.2,15.79 -45.01,20.72c-18.85,6.33 -38.38,10.58 -58.09,13.14c-0.01,-27.42 0.08,-54.83 -0.05,-82.25z"
      />
    </g>
    <g id="#de6926ff">
      <path
        id="Layer"
        className="shp5"
        fill="#de6926"
        d="M172.3,314.15c17.22,8.5 36.47,12.76 55.66,12.61c0.13,27.42 0.04,54.83 0.05,82.25c-33.94,4.17 -68.56,2.8 -102.03,-4.23c15.35,-30.26 31.1,-60.31 46.32,-90.63z"
      />
    </g>
  </svg>
);

export default KiyOhIcon;
