import NewsletterForm from 'components/NewsletterForm/NewsletterForm';
import { FOOTER_NEWSLETTER_COPIES, FormFooter } from 'constants/footer';
import { useIntl } from 'react-intl';
import { Text } from 'ui/Text/Text';
import { cn } from 'utils/cn';
import { ICON_NAMES } from '../../../constants/icon';
import CheckList from '../../Layout/CheckList/CheckList';
import CheckListItem from '../../Layout/CheckList/CheckListItem/CheckListItem';
import Typography from '../../Typography/Typography';

interface FooterFormProps {
  className?: string;
  formType: FormFooter;
}

const FooterForm = ({ className, formType }: FooterFormProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className={cn('flex flex-col rounded bg-footer-form-background px-4 py-8', className)}>
      <Typography fontSize={125} fontWeight="bold" marginBottom={150} textAlign="center">
        {formatMessage({ id: 'footer_content_title' })}
      </Typography>

      <CheckList hasIcons>
        {FOOTER_NEWSLETTER_COPIES.map((copy) => (
          <CheckListItem key={copy} icon={ICON_NAMES.CHECK}>
            <Text className="mb-2 text-footer-form-foreground">{formatMessage({ id: copy })}</Text>
          </CheckListItem>
        ))}
      </CheckList>

      <NewsletterForm formType={formType} />
    </div>
  );
};

export default FooterForm;
