import styled from 'styled-components';
import { spacer } from 'theme';
import Link from '../../../Link/Link';

export const StyledLink = styled(Link)`
  display: flex;
  margin-top: auto;
  align-items: center;
  gap: ${spacer(100)};
`;
