export const CMS_PAGE_QUALIFIER = 'footer';

export const FOOTER_SLOTS = {
  HELP_SLOT: 'footerHelpSlot',
  PAYMENT_SLOT: 'footerPaymentSlot',
  SLOT: 'footerSlot',
  SOCIAL_SLOT: 'footerSocialSlot',
};

export const FORM_FOOTER_TYPES = {
  BOTTOM_FOOTER: 'bottom_footer',
  HELP_FOOTER: 'help_section_footer',
} as const;
export type FormFooter = (typeof FORM_FOOTER_TYPES)[keyof typeof FORM_FOOTER_TYPES];

export const FOOTER_POSITIONS = {
  BOTTOM: 'bottom',
  HELP: 'help',
  PAYMENT: 'payment',
  SOCIAL: 'social',
};

export const FOOTER_NEWSLETTER_COPIES = [
  'footer_subscribe_newsletter_check_1',
  'footer_subscribe_newsletter_check_2',
  'footer_subscribe_newsletter_check_3',
];

export const FOOTER_COMPANY_INFO = [
  { page: 'termsOfService', text: 'footer_info_term_of_service' },
  { page: 'privacyPolicy', text: 'footer_info_privacy' },
  { page: 'disclaimer', text: 'footer_info_disclaimer' },
  { page: 'cookiePolicy', text: 'footer_info_cookies' },
];
