import { Spacer } from 'constants/styling';
import { FooterHelpLink } from 'types/Footer';
import Flex from '../../../Flex/Flex';
import Icon from '../../../Icon/Icon';
import Typography from '../../../Typography/Typography';
import { StyledLink } from './HelpSection.styled';

interface HelpSectionProps {
  className?: string;
  copy?: string;
  copySpacing?: Spacer;
  link: FooterHelpLink;
  title?: string;
}

const HelpSection = ({
  className,
  copy,
  copySpacing = 125,
  link: { color = 'black', href, icon, iconStyling, label, newTab },
  title,
}: HelpSectionProps) => {
  return (
    <Flex flexDirection="column" fullWidth className={className}>
      {title && <Typography fontWeight="bold">{title}</Typography>}

      {copy && <Typography marginBottom={copySpacing}>{copy}</Typography>}

      {href && label && (
        <StyledLink newTab={newTab} url={href}>
          {icon && <Icon type="custom" color={color} iconStyling={iconStyling} name={icon} size={125} />}
          <Typography color={color} fontWeight="bold">
            {label}
          </Typography>
        </StyledLink>
      )}
    </Flex>
  );
};

export default HelpSection;
