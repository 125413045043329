import styled from 'styled-components';
import { spacer } from 'theme';

export const StyledFooterSocials = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${spacer(100)};
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
`;
