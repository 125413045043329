import styled from 'styled-components';
import KiyOhIcon from '../../../Icon/KiyOhIcon/KiyOhIcon';
import Typography from '../../../Typography/Typography';

export const StyledKiyohBadge = styled.div`
  position: relative;
  width: 55px;
  height: 55px;
`;

export const StyledKiyohBadgeIcon = styled(KiyOhIcon)`
  width: 100%;
  height: 100%;
`;

export const StyledKiyohBadgeScore = styled(Typography)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
