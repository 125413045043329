import React from 'react';
import { useIntl } from 'react-intl';
import RatingStars from '../../../Layout/RatingStars/RatingStars';
import Typography from '../../../Typography/Typography';
import config from '../../../../config';
import { StyledKiyohBadge, StyledKiyohBadgeIcon, StyledKiyohBadgeScore } from './KiyOhBadge.styled';
import Link from '../../../Link/Link';
import { useKiyohReviews } from 'features/kiyOh/queries';

const KiyohBadge = () => {
  const { formatMessage } = useIntl();

  const { data: kiyOhReviews } = useKiyohReviews();

  if (!kiyOhReviews?.url) {
    return null;
  }

  return (
    <Link newTab url={kiyOhReviews?.url || config?.kiyOh?.url}>
      <StyledKiyohBadge>
        <StyledKiyohBadgeIcon />
        <StyledKiyohBadgeScore>{kiyOhReviews?.totalScore}</StyledKiyohBadgeScore>
      </StyledKiyohBadge>

      <RatingStars currentRating={kiyOhReviews?.totalScore / 2} iconSize={75} />

      <Typography color="primary-300" fontWeight="bold" textDecoration="underline" fontSize={75}>
        {formatMessage({ id: 'footer_kiyoh_review_amount' }, { reviews: kiyOhReviews?.reviewAmount })}
      </Typography>
    </Link>
  );
};

export default KiyohBadge;
