import { BREAKPOINTS } from 'constants/breakpoints';
import styled, { css } from 'styled-components';
import { breakpointUp, spacer, variable } from 'theme';
import Container from 'ui/Container/Container';
import ContainerBreak from '../ContainerBreak/ContainerBreak';

const sectionSpacing = spacer(200);

export const StyledFooterWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  gap: ${sectionSpacing};
  ${variable('border-top')}

  @media print {
    display: none;
  }
`;

export const StyledContainerBreak = styled(ContainerBreak)`
  margin-top: calc(${sectionSpacing} * -1);
`;

export const StyledDesktopFooter = styled.div`
  display: none;
  ${breakpointUp(
    BREAKPOINTS.LG,
    css`
      display: flex;
    `,
  )}
`;

export const StyledMobileTabletFooter = styled.div`
  ${breakpointUp(
    BREAKPOINTS.LG,
    css`
      display: none;
    `,
  )}
`;
