import Image from 'components/Image/Image';
import Link from 'components/Link/Link';
import { useWebConfig } from 'features/configuration/queries';
import React from 'react';

const EuronicsBadge = () => {
  const { data: webConfig } = useWebConfig();

  const euronicsLogo = webConfig?.euronicsLogo;
  const euronicsUrl = webConfig?.euronicsUrl;

  if (!(euronicsLogo?.url && euronicsUrl)) {
    return null;
  }

  return (
    <Link newTab url={euronicsUrl}>
      <Image alt="Euronics" height={55} src={euronicsLogo?.url} width={150} />
    </Link>
  );
};

export default EuronicsBadge;
